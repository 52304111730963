import React from 'react';

const HomePowered = () => {
  return (
    <div className="row ">
      <div className="col-md-7">
        <h2 className="thin large mb-4">Powered by OAI</h2>
        <p>
          The OAI token is at the heart of OLEX's success. OLEX is a 100% real yield product. All profits made by any
          Olex created products will be passed to OAI token holders
        </p>
        <p>If you believe in AI the way we do, and you believe autonomous products are possible, OAI is for you.</p>
      </div>
      <div className="col-md-5 d-none d-md-block">
        <img src="chart.svg" />
      </div>
    </div>
  );
};

export default HomePowered;
